import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'

import './App.scss';

import Uploader from './Uploader'
import Products from './Products'
import Shop from './Shop'
// import ShopClient from './ShopClient'
import Home from './Home'
import Auth from './Auth'

import ProductsForm from './Products/ProductsForm'
import ProductModifiersEditor from './Modifiers/ProductModifiersEditor'

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <Link to="/">
              <h1 className="App-title">🦙Data-llama</h1>
            </Link>
            <Auth></Auth>
          </header>

          <Route path="/login" component={Auth} ></Route>
          <Route path="/r/:restaurantId" component={Shop}></Route>
          {/* <Route path="/shop/:restaurantId" component={ShopClient}></Route> */}
          <Route path="/" exact component={Home}></Route>
          <Route path="/photos" component={Uploader}></Route>
        </div>
      </Router>
    );
  }
}

export default App;
