import localized from '../utils/makeLocalizedSchema'
import React, {Component} from 'react'

let availabilitySchema = {
  type: 'object',
  properties: {
    timezone: {
      type: 'string',
      title: "Time Zone"
    }

  }
}


let shopSchema = {
  type: 'object',
  required: ["name"],
  properties: {
    name:         localized("Name"),
    description:  localized("Description"),
    cuisine:      localized("Cuisine"),
    address_line_1: {type: 'string'},
    address_line_2: {type: 'string'},
    city: {type: 'string'},
    state: {type: 'string'},
    zip: {type: 'string'},
    phone: {type: 'string'},
    googleCalendarId: {type: 'string'}
  }
}

class LocalizedField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...props.formData};
  }

  onChange(name) {
    return (event) => {
      this.setState({
        [name]: (event.target.value)
      }, () => this.props.onChange(this.state));
    };
  }

  render() {
    const {en, ch} = this.state;
    return (
      <div>
        en: <input type="text" value={en} onChange={this.onChange("en")} />
        zh: <input type="text" value={ch} onChange={this.onChange("zh")} />
      </div>
    );
  }
}
const uiSchema = {
  "name": {
    "ui:field": "localized"
  }
}

const fields = {
  "localized": LocalizedField
}

export {uiSchema, fields}
export default shopSchema
