import React from 'react';
import { Formik, Field, Form } from 'formik';
import {pick} from 'underscore'
import './SubMenuInlineForm.css'
import {debounce} from 'lodash'


let editableKeys = [
  'description', 'draft', 'name', 'disableOrder', 'tag'
]

const SubMenuInlineForm = ({ subMenu }) => {
  return (
    <div>
      {/* <h1>Edit User</h1> */}
      <Formik
        initialValues={pick(subMenu.data(), editableKeys) /** { email, social } */}
        onSubmit={debounce((values, actions) => {
          console.log(values)
          subMenu.ref.update(values).then(() => (actions.setSubmitting(false)))

        }, 1000)}
        render={({ errors, touched, isSubmitting, values, handleSubmit, handleChange }) => {
          const changeWithSave = (e) => {
            handleChange(e) // the effects is setImmediate
            setTimeout(() => handleSubmit(e), 0)
          }
          return(
            <Form className="sub-menu-inline-form">

              {/* <Field type="code" name="code" /> */}
              <Field onChange={changeWithSave} type="text" name="name.en" />
              <Field onChange={changeWithSave} component="textarea" name="description.en" />
              Draft:<Field onChange={changeWithSave} type="checkbox" name="draft" checked={values.draft} />
              Disable Order:<Field onChange={changeWithSave} type="checkbox" name="disableOrder" checked={values.disableOrder} />
              tag<Field onChange={changeWithSave} type="text" name="tag" />


              {/* <button type="submit" disabled={isSubmitting}> */}
              {/* <button type="submit" >
                Save
              </button> */}
            </Form>
        )}}
      />
    </div>
  );
};

export default SubMenuInlineForm
