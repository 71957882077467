import React, {Component} from 'react'
import Photo from '../Photo'


class OtherPhotosDisplay extends Component {
  selectPhoto = (selectedPhoto) => {
    let product = this.props.product
    let oldPhoto = product.get('photo')
    product.ref.update({
      photo: selectedPhoto
    })
  }
  removePhoto = () => {
    let product = this.props.product
    // let oldPhoto = product.get('photo')
    product.ref.update({
      photo: null
    })
  }
  render() {
    let otherPhotos = this.props.product && this.props.product.get('otherPhotos')
    // if (!otherPhotos) {return null}
    return(
      <div style={{
        width: "4em",
      }}>
        { otherPhotos &&
          <div>
            {Object.keys(otherPhotos).map(id => {
              let photo = otherPhotos[id]
              return(
                <div className="choice"
                  key={id}
                  onClick={this.selectPhoto.bind(this, photo)}
                  >
                  <Photo {...photo} width={30} />
                </div>
              )
            })}
            <div
              onClick={this.removePhoto}
              >❌</div>
          </div>
        }
        
      </div>
    )
  }
}

export default OtherPhotosDisplay
