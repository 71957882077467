import React, {Component} from 'react'
import Form from "react-jsonschema-form";
import 'bootstrap/dist/css/bootstrap.min.css'


const schema = {
  title: "Modifiers Editor",
  type: "object",
  properties: {
    modgroups: {
      type: "array",
      items: {
        title: "Modifier Group",
        type: "object",
        properties: {
          name: {
            type: "object",
            properties: {
              en: { type: "string", title: "English" }
            }
          },
          price: {
            type: "number",
            title: "Price In Cents"
          },

        }
      }
    }
  }
}



class PorductsModifiersEditor extends Component {
  render() {
    return(
      <div className="modifiers-form container-fluid">
        <h1>Modifiers Editor</h1>


        <Form schema={schema}
              onSubmit={console.log}
          ></Form>
      </div>
    )
  }
}

export default PorductsModifiersEditor
