import React, {Component} from 'react'

import makeImageURL from './afa-photo'


class Photo extends Component {
  render() {

    let {url, width, height, alt, type, pid, s, id, e} = this.props
    const src = makeImageURL(this.props, {width, height})

    return(
      (src === 'no-pic') ? <div>no-pic</div> : <img src={src} alt={alt}/>
    )
  }
}

export default Photo
