import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Button, Dialog} from '@material-ui/core';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import cloudinary from 'cloudinary-core'
import db from '../Firebase'
import _ from 'underscore'
import Photo from '../Photo'
var cl = cloudinary.Cloudinary.new({cloud_name: 'dgps1radv', secure: true});

class Products extends Component {

  onProductSortEnd(subMenuDoc, {oldIndex, newIndex}) {
    let orderIndex = subMenuDoc.data().orderIndex

    let newOrderIndex = arrayMove(orderIndex, oldIndex, newIndex)
    subMenuDoc.ref.update({
      orderIndex: newOrderIndex
    })
    // console.log(arrayMove())
  }
  render() {

    return(
      <div className="products">
        {/* <h1>Dishes belonging to $shopId: <code>{this.props.match.params.restaurantId}</code></h1> */}

        <SortableSubmenus subMenus={this.props.shop.subMenus} />


        <Button variant="raised"
                color="primary">
                New Category</Button>
        <Button variant="raised"
                color="primary">
                New Dish</Button>


        <ul>

        </ul>
      </div>
    )
  }
}
const SortableProduct = SortableElement(({product}) => {
  const photo = product.get('photo')
  let imgixId = photo && (photo.type === 'g') && `_g_${photo.s}/${photo.id}.${photo.e}`
  let oUrl = product.data().photo && product.data().photo.url
  let pid = product.data().photo && product.data().photo.pid
  // if (oUrl) {
  //   src = cl.url(oUrl, {width: 70, crop: "fill",  type: 'fetch'})
  // }
  return (<div>
    <Link to={`./products/${product.id}`}>
      <Photo {...product.data().photo} width={70}/>
      {/* {product.data().name.en} */}</Link>
      <input type="text" style={{width: '200px'}} value={imgixId || oUrl || pid}/>
      {pid && <span>👋 Yes, this will work, even if it doesn't look like a url</span>}
      {/* <span>{oUrl}</span> */}
  </div>)
  }
);
const SortableProducts = SortableContainer(({products}) => {
  return (

    <div style={{}}>
      {products.map((product, index) => {

        return(
          <SortableProduct key={`item-${index}`} index={index} product={product} />

        )
      })}

    </div>
  );
});
const SortableSubMenu = SortableElement(({submenu}) => {


  return (<div>
    <h2>{submenu.data().name.en}</h2>
    <SortableProducts pressDelay={200} axis="xy" products={submenu.products} />
  </div>)
  }
);
const SortableSubmenus = SortableContainer(({subMenus}) => {
  return (

    <div >
      {subMenus.map((submenu, index) => {
        return(
          <SortableSubMenu key={`item-${index}`} index={index} submenu={submenu} />

        )
      })}

    </div>
  );
});



export default Products
