import React, {Component} from 'react'
import Form from "react-jsonschema-form";
import makeLocalizedSchema from '../utils/makeLocalizedSchema'

const schema = {
  title: "Dish Form",
  type: "object",
  required: [
    "name"
  ],
  properties: {
    "name": {
      title: "Product Name",
      type: 'object',
      properties: makeLocalizedSchema()
    },
    "description": {
      title: "Description",
      type: 'object',
      properties: makeLocalizedSchema()
    }
  }
}

class ProductsForm extends Component {
  render() {
    let productJson = this.props.product.data()
    return(
      <div className="products-form">
        {/* <h1>Form</h1> */}


        <Form schema={schema}
              formData={productJson}
              onSubmit={console.log}
          ></Form>
      </div>
    )
  }
}

export default ProductsForm
