// Initialize Firebase
import firebase from "firebase"
// Required for side-effects
require("firebase/firestore");

var config = {
  apiKey: "AIzaSyBh1iH6dA-dv5oD3AzEQ5-x9PVDulGLCiY",
  authDomain: "avocadoftw-c5639.firebaseapp.com",
  databaseURL: "https://avocadoftw-c5639.firebaseio.com",
  projectId: "avocadoftw-c5639",
  storageBucket: "afa-photos",
  // messagingSenderId: "939263029027"
};
// console.log(firebase)
firebase.initializeApp(config);

var db = firebase.firestore();

export {firebase}
export default db
export const storageRef = firebase.storage().ref()
