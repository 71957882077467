import React, {useState, useEffect, Component} from 'react'
import {Link} from 'react-router-dom'
import {Button, Dialog} from '@material-ui/core';
import ProductsForm from './ProductsForm'
import {SortableContainer, SortableElement,SortableHandle, arrayMove} from 'react-sortable-hoc';
import cloudinary from 'cloudinary-core'
import db from '../Firebase'
import _ from 'underscore'
import ProductInlineForm from './ProductInlineForm'
import SubMenuInlineForm from './SubMenuInlineForm'
import Dropzone from 'react-dropzone'
import request from 'superagent'
import './index.css'
import Photo from '../Photo'
import OtherPhotosDisplay from './OtherPhotosDisplay'
import GSCProductImageDropzone from '../Photos/GSCProductImageDropzone'
import {get} from 'lodash'
import {debounce} from 'lodash'


var cl = cloudinary.Cloudinary.new({cloud_name: 'dgps1radv', secure: true});

function Products (props) {
  const [query, setQuery] = useState('')
  const {shop} = props

  console.log('query', query)

  const onSubMenuSortEnd = ({oldIndex, newIndex, collection}) => {
    console.log(oldIndex, newIndex)
    let subMenusOrderIndex = props.shop.subMenus.map(doc => doc.id)
    let newSubMenusOrderIndex = arrayMove(subMenusOrderIndex, oldIndex, newIndex)
    props.shop.ref.update({
      subMenusOrderIndex: newSubMenusOrderIndex
    })
  }
  let results = []
  if (query.length > 1) {
    shop.subMenus.forEach((submenu, index) => {
      submenu.products.forEach((product, index) => {
      
        const name = get(product.data(), 'name.en', '').toLowerCase()
        // console.log('found,', name)
        if (name && name.indexOf(query) !== -1) {
          // console.log('found,',product.id)
          results.push({product})
        }
      })
    })
  }
 

    return(
      <div className="products">

        <div className="search">
          <div className="searchbox">
            <input type="text" value={query} onChange={(e)=> {setQuery(e.target.value)}} />
          </div>
          <div className="results">
            {results.map(({product})=> {
  
              return(
                <div className="product">
                <GSCProductImageDropzone
                    product={product}
                  />
                  <ProductInlineForm
                    key={product.id}
                    product={product} />
                </div>
              )
            })}
          </div>
        </div>


        <Button variant="contained"
                color="primary"
                onClick={() => {
                  let subMenusOrderIndex = props.shop.subMenus.map(doc => doc.id)
                  props.shop.ref.parent.add({
                    draft: true,
                    type: "subMenu",
                    shopId: props.shop.get('shopId'),
                    orderIndex: []
                  }).then((docRef) => {
                    if (!docRef.id) { throw new Error('no id') }
                    let newSubMenusOrderIndex = [...subMenusOrderIndex, docRef.id]
                    props.shop.ref.update({
                      subMenusOrderIndex: newSubMenusOrderIndex
                    })
                  })
                }}
                >
                New Category to Bottom</Button>

        <SortableSubmenus
          useDragHandle
          onSortEnd={onSubMenuSortEnd}
          subMenus={shop.subMenus} />
        <ul>

        </ul>
      </div>
    )
}
const SortableProduct = SortableElement(({isSelected, onTouchStart, product, rowNumber}) => {
  // let src
  // let oUrl = product.data().photo && product.data().photo.url
  // if (oUrl) {
  //   src = cl.url(oUrl, {width: 90, crop: "fill",  type: 'fetch'})
  const isSelectedClass = (isSelected) ? " selected" : ""
  // }
  return (<div className={"product" + isSelectedClass} onClick={onTouchStart}>
    <DragHandle></DragHandle>
    <GSCProductImageDropzone
      product={product}
    />
    <OtherPhotosDisplay product={product} />

    <ProductInlineForm
      
      rowNumber={rowNumber}
      product={product} />

    <button onClick={() => console.log(product.data())}>?</button>
    <button onClick={() => {
      product.ref.parent.add(
        product.data()
      )
    }}>dup</button>
    <button onClick={() => {
      if(window.confirm(`delete ${product.get('name.en')}?`)) {
        product.ref.delete().then().catch(console.log)
      }
    }}>🚮</button>
  </div>)
  }
);
const SortableProducts = SortableContainer(({products}) => {
  const [selectedProduct, setSelectedProduct] = useState(null)
  function handleTouchProduct (id) {
    console.log('touched', id)
    setSelectedProduct(id)
  }
  // console.log(products && products[0].data())
  return (

    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
      {products.map((product, index) => {
        const isSelected = (selectedProduct === product.id) 
        return(
          <SortableProduct 
          isSelected={isSelected}
          onTouchStart={handleTouchProduct.bind(this, product.id)}
          key={product.id} rowNumber={index} index={index} product={product} />
        )
      })}

    </div>
  );
});

const SortableSubMenu = SortableElement(({submenu}) => {
  return(<SubMenuBlock submenu={submenu}></SubMenuBlock>)
})
class SubMenuBlock extends Component {
  state = {
    showProducts: false,
    selectedProductId: false,
  }
  toggleProducts = () => {
    this.setState({showProducts: !this.state.showProducts})
  }
  render () {
    const submenu = this.props.submenu
    const {showProducts} = this.state
    return (<div className="sub-menu">
      <header>
        <DragHandle></DragHandle>
        <button onClick={this.toggleProducts}>{showProducts ? "▼" : "▶︎"} {submenu.products.length}</button>
        <SubMenuInlineForm subMenu={submenu}></SubMenuInlineForm>
        <Button
          onClick={() => { // New Dish Function
            submenu.ref.parent.add({
              draft: true,
              type: "product",
              shopId: submenu.get('shopId'),
              subMenuId: submenu.id
            })
          }}
                // variant="contained"
                color="primary">
                New Dish</Button>
      </header>
      {false &&
        <div className="controls">
          <button onClick={() => {
            // const submenuId = submenu.id
            const updates = submenu.products.map((product) => {
              const price = Number(product.get('price'))
              if ((price > 0)) {
                return product.ref.update({
                  price: price + 1
                })
              }
            })
            Promise.all(updates).then(() => {
              // location.reload()
            })
          }}>Add $1 to non zero and non text prices</button>
          <button onClick={() => {
            // const submenuId = submenu.id
            const updates = submenu.products.map((product) => {
              const price = Number(product.get('price'))
              if ((price > 1)) {
                return product.ref.update({
                  price: price - 1
                })
              }
            })
            Promise.all(updates).then(() => {
              // location.reload()
            })
          }}>Subtract $1</button>
        </div>
      }
      {showProducts && <SortableProducts useDragHandle axis="xy" onSortEnd={({oldIndex, newIndex}) => {
        submenu.ref.update({ orderIndex: arrayMove(submenu.products.map(p=>p.id), oldIndex, newIndex) })
        // console.log()
      }} products={submenu.products} />}
    </div>)
  }
}

const SortableSubmenus = SortableContainer(({subMenus}) => {
  return (

    <div >
      {subMenus.map((submenu, index) => {
        return(
          <SortableSubMenu key={submenu.id} index={index} submenu={submenu} />

        )
      })}

    </div>
  );
});

const DragHandle = SortableHandle(() =>
  <span
  
    style={{
      fontSize: "1.5em",
      cursor: '-webkit-grab',
      display: "block",
      width: "20px",
      height: "50px",
      background: "black"
    }}
    >&nbsp;</span>
)
export default Products
