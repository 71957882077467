import React, {Component, useState} from 'react'
import {Button} from '@material-ui/core';
import updateKVCache from './updateKVCache'

export default function KVCacheUpdateButton ({shopId}) {
  const [updateState, setUpdateState] = useState('Deploy Database Live')
  return(
    <Button
      variant="raised"
      color="secondary"
      disabled={updateState === 'Updating... This may take up to a minute.'}
      onClick={async () => {
        setUpdateState('Updating... This may take up to a minute.')
        try {
          const {dur, success, json} = await updateKVCache(shopId)
          setUpdateState(`updated: ${json.menu.shopId}`)
          console.log(`${success ? "✅" : "❌"} ${shopId} ${dur}ms`)
        } catch (e) {
          setUpdateState(`error: ${e.message}`)
        }
      }}
      >{updateState}</Button>
  )
}
