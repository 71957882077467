import React, {Component} from 'react'
import db from '../Firebase'
import {Link} from 'react-router-dom'
import updateKVCache from '../utils/updateKVCache'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      all: []
    }
    db.collection('all')
      .where('type', '==', 'shop')
      .get().then((qs) => {

        this.setState({all: qs.docs})
      })
  }
  refreshAllKVStore = async () => {
    for (const shopDoc of this.state.all) {
      let name = shopDoc.get('name')
      let shopId = shopDoc.get('shopId')
      const {dur, success} = await updateKVCache(shopId)
      console.log(`${success ? "✅" : "❌"} ${shopId} ${dur}ms`)
    }

  }
  createNewShop = () => {
    let allRef = db.collection('all')
    let batch = db.batch()
    let shopId = window.prompt('Enter Shop Id:')
    let shop = {
      type: 'shop',
      shopId,
      subMenusOrderIndex: []
    }
    batch.set(allRef.doc(), shop)
    let subMenu = {
      draft: true,
      type: "subMenu",
      shopId,
      orderIndex: []
    }
    let subMenuRef = allRef.doc()
    batch.set(subMenuRef, subMenu)
    let product = {
      draft: true,
      type: "product",
      shopId,
      subMenuId: subMenuRef.id
    }
    batch.set(allRef.doc(), product)
    batch.commit()
  }
  componentWillUnmount() {
    // this.unsubscribeArray.forEach(f => f())
  }

  render() {
    return (<div>
      <h1>Avocado CMS</h1>

      {(process.env.NODE_ENV === 'development') &&
      <div className="controls">
        <button
          onClick={this.createNewShop}
          className="new-shop">New Shop</button>

        <button
          onClick={this.refreshAllKVStore}
          >Refresh All KV Store</button>
      </div>
      }

      {(this.state.all.length === 0) ? <div>Loading Shops...</div> : null}

      {this.state.all
        .sort(function (first, second) {
          const firstName = first.get('name.en') || first.get('shopId')
          const secondName = second.get('name.en') || second.get('shopId')
          if (firstName > secondName) { return 1}
          if (firstName < secondName) { return -1}
          return 0
        })
        .map((shopDoc) => {
        console.log(shopDoc.get('name'))
        // if (!shopDoc.get('shopId')) {console.log(shopDoc.data())}
        let shopId = shopDoc.get('shopId')
        let shopName = shopDoc.get('name') && shopDoc.get('name').en
        return(
          <div><Link to={`/r/${shopId}/products`}>{shopName || shopId}</Link> ({shopId})</div>
        )
      })}

    </div>)
  }
}

export default Home
