let locales = [
  ["English", "en"],
  // ["Chinese", "zh"],
  // ["Spanish", "es"]
]


const makeLocalizedSchema = (title, extensionProps) => {
  let properties = {}
  locales.forEach(([name, code]) => {
    properties[code] = Object.assign({type: "string", title: name}, extensionProps)
  })

  return({
    title,
    properties,
    type: 'object'
  })
}

export default makeLocalizedSchema
