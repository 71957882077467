import React, {Component} from 'react'
import { Formik, Field } from 'formik';
import Photo from '../Photo'

let modGroupDict = {
  single: ({modifiers}) => {
    return(
      <div>
        {modifiers.map(mod => (
          <Field key={mod.id} type="radio" name=""/>
        ))}
      </div>
    )
  }
}
// {modgroups.map(({name, type, modifiers}) => {
//   let ModGroupType = modGroupDict(type)
//   return(
//
//   )
// })}

class View extends Component {
  render() {
    let modgroups = this.props.modgroups
    // console.log(modgroups)
    // let form =
    return(
      <div className="modgroup-preview">
        <h3>Preview</h3>
        <Formik
          initialValues={{email: ''}}
          onSubmit={(values, actions) => console.log(values)}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              {modgroups.map(modgroup => (
                <div key={modgroup.id}>
                  <h4>{modgroup.name}{modgroup.required && <span>(required)</span>}</h4>
                  <div className="modgroup-preview__modgroups">
                    {modgroup.modifiers ? modgroup.modifiers.map(modifier => (
                      <label htmlFor={modifier.id} key={modifier.id}>
                        {modifier.url ? <div>
                          <Photo url={modifier.url} width={80} />
                        </div> : <div></div>}
                        <span className="modgroup-preview__modifier-name">{modifier.name || modifier.id}</span>
                        {/* <Field id={modifier.id} type="checkbox" name={modifier.id}></Field> */}
                      </label>
                    )): null}</div>
                </div>
              ))}
              <button type="submit">Submit</button>
            </form>
          )}
          >

        </Formik>
      </div>
    )
  }
}

export default View
