import React, {Component} from 'react'
import GeneratedForm from "react-jsonschema-form";
import Dropzone from 'react-dropzone'
import Photo from '../Photo'
import OtherLogosDisplay from '../Products/OtherLogosDisplay'

import request from 'superagent'

import traverse from 'traverse'

import shopSchema, {uiSchema, fields} from './shopSchema'

class ShopSettings extends Component {
  constructor (props) {
    super()

    this.handleSubmit = ({formData}) => {
      formData = Object.assign({}, formData)
      traverse(formData).forEach(function (x) {
        if (x == undefined) {
          this.update(null)
        }
      })
      console.log(formData)
      props.shop.ref.update(formData)
    }
  }
  render() {
    let shop = this.props.shop

    return(
      <React.Fragment>
        <h2>Shop Settings</h2>

        <div>
          {/* <GSCProductImageDropzone

            ></GSCProductImageDropzone> */}
          <Dropzone
            onDrop={(acceptedFiles) => {
              let upload = request.post('https://api.cloudinary.com/v1_1/dgps1radv/image/upload')
                              .field('upload_preset', 'vepfnbhl')
                              .field('file', acceptedFiles[0]);

              upload.end((err, response) => {
                if (err) {
                  console.error(err);
                }
                if (response.body.secure_url !== '') {
                  let { public_id: pid, width: w, height: h } = response.body
                  // save to database
                  let oldPhoto = shop.get('logo')
                  let newPhoto = { pid, type: 'cl', w, h, id: pid }
                  let diff = {
                    logo: newPhoto,
                    [`otherLogos.${pid}`]: newPhoto
                  }
                  if (oldPhoto) { diff[`otherLogos.${oldPhoto.id}`] = oldPhoto }
                  shop.ref.update(diff)
                }
              });
            }}
            className='product-image-dropzone'>
            <Photo {...shop.get('logo')} width={90}/>
          </Dropzone>

          

          <GeneratedForm
            schema={shopSchema}
            formData={this.props.shop.data()}
            onSubmit={this.handleSubmit}
          />


        </div>
      </React.Fragment>
    )
  }
}



export default ShopSettings
