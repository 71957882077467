import React from 'react'
import Uploader from './Uploader'

global.UPLOADCARE_PUBLIC_KEY =  'b0ebeb9de7600496d492'

const UploaderWrapper = () => (
  <React.Fragment>
    <h1>Demo of Uploadcare Widget in React app</h1>

    <p>
      <label htmlFor='images'>Your images:</label>{' '}
      <Uploader id='images' name='images' data-images-only data-multiple />
    </p>
    <p>
      <label htmlFor='images'>Predefined image:</label>{' '}
      <Uploader
        id='image'
        name='image'
        value='https://ucarecdn.com/e7e46bb5-685b-45bf-abc6-1dfbcfb10fec/example.jpg'
        data-images-only />
    </p>
  </React.Fragment>
)

export default UploaderWrapper
