import React, {Component} from 'react'
import {Route, Link} from 'react-router-dom'
import brace from 'brace'
import AceEditor from 'react-ace';
import 'brace/mode/yaml'
import 'brace/theme/vibrant_ink'
import db from '../Firebase'
import yaml from 'js-yaml'
import View from './View'
import './modifiers.css'
import SubMenuModifiers from './SubMenuModifiers'




function validate(modgroupsInput) {
  return modgroupsInput
}

class Modifiers extends Component {
  constructor () {
    super()
    this.state = {}
  }

  handleSave(id) {
    let modgroupsYAMLString = (this.refs[id].editor.getValue())
    // save raw YAML regardless of validity
    let docRef = db.collection('all').doc(id)
    let batch = db.batch()
    batch.update(docRef, { modgroupsYAMLString })

    // validate json translation, then save if valid.
    try {
      let modgroupsInput = yaml.safeLoad(modgroupsYAMLString)
      console.log('safeload:', modgroupsInput)
      // console.log(modgroupsInput)
      let modgroups = modgroupsInput
      batch.update(docRef, { modgroups })
      this.setState({ ['error_' + id]: 'valid' })
    } catch (e) {
      console.log('not valid yaml')
      this.setState({ ['error_' + id]: e })
    }

    batch.commit()
  }

  render() {
    let {subMenus} = this.props.shop

    let subMenu = subMenus[0]
    return(
      <div>
        <h2>Modifiers</h2>

        <nav className="modifier-nav">
          {subMenus.map(subMenu => (<Link key={subMenu.id} to={`${this.props.match.url}/${subMenu.id}`}>{subMenu.get('name').en}</Link>))}
        </nav>

        <Route path={`${this.props.match.url}/:subMenuId`}
          render={({match}) => (
            <SubMenuModifiers shop={this.props.shop} match={match} />
          )}
       />
      </div>
    )
  }
}

export default Modifiers
