import React, {useContext} from 'react'
import Dropzone from 'react-dropzone'
import { storageRef } from "../Firebase"
import Photo from '../Photo'
import ShopContext from '../Shop/ShopContext'

function toSeoUrl(url) {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
        .replace(/\s+/g,'-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g,'-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g,'')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g,'-')             // Remove duplicate dashes
        .replace(/^-*/,'')              // Remove starting dashes
        .replace(/-*$/,'');             // Remove trailing dashes
}



async function makeImageName ({product, storageRef, shop, file, count=''}) {
  const extension = toSeoUrl(file.name.match(/\.[0-9a-z]+$/i)[0])
  const shopName = toSeoUrl(shop.get('name.en'))
  // const shopId = product.get('shopId')
  const productName = toSeoUrl(product.get('name.en'))
  const filename = `${productName}${count ? '-' : ''}${count}`
  const url = `${shopName}/${filename}.${extension}`
  try {
    await storageRef.child(url).getDownloadURL()
    return await makeImageName({product, storageRef, shop, file, count: (Number(count) || 0) + 1})
  } catch (e) {
    return { shopName, filename, extension }
  }


}

export default function GSCProductImageDropzone ({product}) {
  const shop = useContext(ShopContext)

  return(
    <Dropzone
      onDrop={async (acceptedFiles) => {
        console.log(acceptedFiles[0])
        // const
        const { shopName, filename, extension } = await makeImageName({product, storageRef, shop, file: acceptedFiles[0]})
        const fileRef = storageRef.child(`${shopName}/${filename}.${extension}`)
        // fileRef.getDownloadURL().then((r) => {console.log(r)}).catch((e) => { console.log('caught', e)})
        const reader = new FileReader()
        reader.readAsDataURL(acceptedFiles[0])
        reader.onload = function (e) {
          const img = new Image
          img.src = e.target.result
          img.onload = async function () {
            const h = this.naturalHeight
            const w = this.naturalWidth
            const snapshot = await fileRef.put(acceptedFiles[0])

            let oldPhoto = product.get('photo')
               let newPhoto = { type: 'g', w, h, id: filename, s: shopName, e: extension }
               let diff = {
                 photo: newPhoto,
                 [`otherPhotos.${filename}`]: newPhoto
               }
               if (oldPhoto) { diff[`otherPhotos.${oldPhoto.id}`] = oldPhoto }
               console.log(diff)
               product.ref.update(diff)
             }

        }

        // img.on
        // console.log(acceptedFiles[0])
        // fileRef.put(acceptedFiles[0]).then(function (snapshot) {
        //   console.log('upload success')
        //
        //
        //   //     let oldPhoto = product.get('photo')
        //   //     let newPhoto = { pid, type: 'cl', w, h, id: pid }
        //   //     let diff = {
        //   //       photo: newPhoto,
        //   //       [`otherPhotos.${pid}`]: newPhoto
        //   //     }
        //   //     if (oldPhoto) { diff[`otherPhotos.${oldPhoto.id}`] = oldPhoto }
        //   //     product.ref.update(diff)
        //   //   }
        //
        // })

      }}
      className='product-image-dropzone'>
      <Photo {...product.get('photo')} width={90}/>
    </Dropzone>
  )
}
