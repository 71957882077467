import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import brace from 'brace'
import AceEditor from 'react-ace';
import 'brace/mode/yaml'
import 'brace/theme/vibrant_ink'
import db from '../Firebase'
import yaml from 'js-yaml'
import View from './View'
import './modifiers.css'
import ErrorBoundary from '../utils/ErrorBoundary'
import firebase from 'firebase'

function validate(modgroupsInput) {
  return modgroupsInput
}

class Modifiers extends Component {
  constructor () {
    super()
    this.state = {}
  }

  handleSave(id) {
    console.log('saving')
    let modgroupsYAMLString = (this.refs[id].editor.getValue())
    console.log('raw string:', modgroupsYAMLString)
    // save raw YAML regardless of validity
    let docRef = db.collection('all').doc(id)
    let batch = db.batch()
    batch.update(docRef, { modgroupsYAMLString })
    // validate json translation, then save if valid.
    try {
      let modgroupsInput = yaml.safeLoad(modgroupsYAMLString)
      if (modgroupsYAMLString === '') {
        modgroupsInput = firebase.firestore.FieldValue.delete() // deletes the modgroups field
      }
      console.log(modgroupsInput)
      let modgroups = modgroupsInput
      batch.update(docRef, { modgroups })
      this.setState({ ['error_' + id]: 'valid' })
    } catch (e) {
      console.log('not valid yaml')
      this.setState({ ['error_' + id]: e })
    }

    batch.commit()
  }

  render() {
    let {subMenus} = this.props.shop

    let subMenu = subMenus.find(sm => sm.id === this.props.match.params.subMenuId)
    return(
      <div>
        <h2>Modifiers</h2>


        {subMenu.products.map((product) => {
          let modgroupsYAMLString = product.get('modgroupsYAMLString') || ''
          let error = this.state['error_' + product.id]
          let message
          if (error === 'valid') {
            message = 'document valid'
          } else if (error) {
            message = error.message
          }

          let modgroups = product.get('modgroups') || []
          return(
            <div className="product-modifiers-editor" key={product.id}>

              <div className="product-modifiers-editor__header">
                {product.get('name') && product.get('name').en}
                <button onClick={this.handleSave.bind(this, product.id)}>Save (⌘+S)</button>
                {message ? <p>{message}</p> : null}
              </div>
              <div className="content">
                <div className="editor">

                  <div>
                    <AceEditor
                      value={modgroupsYAMLString}
                      ref={product.id}
                      name={product.id}
                      mode="yaml"
                      theme="vibrant_ink"

                      showGutter={false}
                      tabSize={2}
                      width='700px'
                      height='400px'
                      debounceChangePeriod={1000}
                      commands= {[
                        { name: 'save doc',
                          bindKey: {win: 'Ctrl-S', mac: 'Command-S'},
                          exec: (editor) => { this.handleSave(product.id) }
                        }
                      ]}
                      editorProps={{
                        height: '400px',
                        width: '200px',


                      }}
                    />
                    {/* <textarea name="" id="" cols="30" rows="10"></textarea> */}
                  </div>
                </div>
                <ErrorBoundary>
                  <View modgroups={modgroups}></View>
                </ErrorBoundary>
              </div>

            </div>
          )
        })}
      </div>
    )
  }
}

export default Modifiers
