// const host = "https://afa-menu-db-update.afoodapart.workers.dev"
const host = "https://perfect-spiky-droplet.glitch.me/shops"

export default async function (shopId) {
  const startTime = Date.now()
  // const response  = await fetch(`${host}/?shopId=${shopId}`)
  const response  = await fetch(`${host}/${shopId}`)
  // get json
  const json = await response.json()
  const endTime   = Date.now()
  const dur = endTime - startTime
  const success = response.ok
  return {dur, success, json}
}
