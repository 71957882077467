import React from 'react';
import { Formik, Field, Form } from 'formik';
import {pick} from 'underscore'
import ProductSubMenuSelect from './ProductSubMenuSelect'
// import FormikSubmitOnChange from './FormikSubmitOnChange'
import {debounce} from 'lodash'


let editableKeys = [
  'code', 'description', 'draft', 'name', 'price', 'subMenuId', 'priceDisplay', 'tags'
]

const ProductInlineForm = ({ product, rowNumber }) => {
  const subMenuId = product.data().subMenuId
  // console.log(product.data())
  // console.log('debug', rowNumber)
  function handleKeyDown (field_id, e) {
    console.log(e.keyCode)
    // 38 - up
    // 40 - down
    if (e.keyCode === 38) {
      // UP
      let nextRowNumber = (Number(rowNumber) === 0) ? 0 : (rowNumber - 1)
      // console.log
      const id = `${subMenuId}-${field_id}-${nextRowNumber}`
      // console.log(id)
      const el = document.getElementById(id)
      if (el) {
        el.focus() 
        setTimeout(()=> {
          el.select()
        }, 0)
        
      }
    }
    if (e.keyCode === 40) {
      // UP
      let nextRowNumber = (rowNumber + 1)
      // console.log(`${field_id}-${nextRowNumber}`)
      const el = document.getElementById(`${subMenuId}-${field_id}-${nextRowNumber}`)
      if (el) {
        el.focus() 
        setTimeout(()=> {
          el.select()
        }, 0)
      }
    }
  }
  return (
    <div>
      {/* <h1>Edit User</h1> */}
      <Formik
        // enableReinitialize
        initialValues={pick(product.data(), editableKeys) /** { email, social } */}
        onSubmit={debounce((values, actions) => {
          console.log('Submitted')
          console.log(values)
          product.ref.update(values)
        }, 1000)}
        render={({ errors, touched, isSubmitting, values, handleSubmit, setFieldValue, handleChange, submitForm}) => {
          const changeWithSave = (e) => {
            handleChange(e) // the effects is setImmediate
            setTimeout(() => handleSubmit(e), 0)
          }
          // console.log('formik', values)
          return(
            <Form className="product-inline-form">

              {/* <Field type="code" name="code" /> */}
              <Field
                className="name-input-field"
                autoComplete="off"
                onKeyDown={handleKeyDown.bind(this, 'name.en')}
                id={`${subMenuId}-name.en-${rowNumber}`}
                onChange={changeWithSave}
                type="text"
                name="name.en" />
              <Field
                autoComplete="off"
                type="text"
                id={`${subMenuId}-price-${rowNumber}`}
                onFocus={(e)=> {e.target.select()}}
                onKeyDown={handleKeyDown.bind(this, 'price')}
                onChange={changeWithSave} name="price" />
              <Field
                autoComplete="off"
                id={`${subMenuId}-name.kr-${rowNumber}`}
                onKeyDown={handleKeyDown.bind(this, 'name.kr')}
                placeholder='korean'
                name="name.kr"
                onChange={changeWithSave} type="text" />

              {/* <Field onChange={changeWithSave} type="text" name="name.kr" placeholder='korean' /> */}
              {/* <Field onChange={changeWithSave} type="text" name="name.zh" placeholder='chinese' /> */}
              {/* <Field onChange={changeWithSave} component="textarea" name="priceDisplay.en" placeholder="price display" /> */}

              <Field onChange={changeWithSave} type="checkbox" name="draft" checked={values.draft} />
              <Field onChange={changeWithSave} component="textarea" name="description.en" />
              <Field onChange={changeWithSave} type="text" name="tags" placeholder="tags..." />

              <ProductSubMenuSelect
                value={values.subMenuId}
                setFieldValue={(...props) => { setFieldValue(...props); setTimeout(() => submitForm(), 0) }} />

              {/* <button type="submit" disabled={isSubmitting}> */}
              {/* <FormikSubmitOnChange handleSubmit={handleSubmit} /> */}
              {/* <button type="submit" >
                Save
              </button> */}
            </Form>
        )}}
      />
    </div>
  );
};

export default ProductInlineForm
