import React, {Component} from 'react'
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom'
import {Button} from '@material-ui/core';

import KVCacheUpdateButton from '../utils/KVCacheUpdateButton'
import Products from '../Products'
import ProductsPhotoUrls from '../Products/PhotoUrls'
import ProductsForm from '../Products/ProductsForm'
import Modifiers from '../Modifiers'
import db from '../Firebase'
import _ from 'underscore'
import ShopSettings from './ShopSettings'
import ShopContext from './ShopContext'
import './shop.css'

function sortDocsWithIndex (docs, sortIndex) {
  // let docIndex = {}
  // docs.forEach((doc) => docIndex[doc.id] = doc)
  // return sortIndex.map(id => docIndex[id]).filter(d=>d)
  return _.sortBy(docs, (doc) => (sortIndex.indexOf(doc.id) || -1))
}

function parseFirestoreAllDocs (qs) {
  let types = _.groupBy(qs.docs, (doc) => doc.get('type'))
  let {product: products, subMenu: subMenus, shop: shops} = types
  console.log(types)
  // console.log('current index:')
  // console.log(shops[0].get('subMenusOrderIndex'))
  let nested = Object.assign(Object.create(shops[0]), {
    subMenus: sortDocsWithIndex(subMenus, shops[0].get('subMenusOrderIndex')).map((subMenu) => {
      return Object.assign(Object.create(subMenu), {
        products: sortDocsWithIndex(products.filter(p=>(p.get('subMenuId')===subMenu.id)), subMenu.get('orderIndex'))
      })
    })
  })
  return nested
}


class Shop extends Component {
  constructor(props) {
    super()
    this.state = {
      all: null
    }

    let shopId = props.match.params.restaurantId
    this.unsubscribeArray = []
    this.unsubscribeArray.push(
      // docs: https://firebase.google.com/docs/firestore/query-data/listen
      db.collection('all')
        .where('shopId', '==', shopId)
        .onSnapshot((qs) => {
          // console.log('download shop')
          this.setState({all: qs})
        })
    )
  }
  componentWillUnmount() {
    this.unsubscribeArray.forEach(f => f())
  }
  render() {
    let allDocs = this.state.all
    if (!allDocs) { return 'loading...' }
    // console.log(allDocs.data)
    let shop = parseFirestoreAllDocs(allDocs)
    let shopId = this.props.match.params.restaurantId

    const absolutizePath = (relativePath) => {
      return `${this.props.match.url}/${relativePath}`
    }

    return(
      <ShopContext.Provider value={shop}>
        <div className="shop">
          <header className="shop-header">
            <div className="shop-name">{shopId}</div>
            <div className="tools">
              <KVCacheUpdateButton shopId={shopId}></KVCacheUpdateButton>
            </div>
          </header>
          <nav className="shop-nav">
            <NavLink exact to={this.props.match.url}>Profile</NavLink>
            <NavLink to={absolutizePath('products')}>Products</NavLink>
            <NavLink to={absolutizePath('products-photo-urls')}>Photos</NavLink>
            <NavLink to={absolutizePath('modifiers')}>Modifiers</NavLink>
          </nav>

          <div className="content">
            <Route
              exact
              path={this.props.match.url}
              render={() => <ShopSettings  shop={shop} />}
            />

            <Route
              exact
              path={`${this.props.match.url}/products`}
              render={() => <Products  shop={shop} />}></Route>

            <Route
              exact
              path={`${this.props.match.url}/products-photo-urls`}
              render={() => <ProductsPhotoUrls  shop={shop} />}></Route>
            <Route

              path={absolutizePath('modifiers')}
              render={({match}) => <Modifiers match={match} shop={shop} />}></Route>
            <Route
              path={`${this.props.match.url}/products/:productId`}
              render={({match}) => {
                let product = allDocs.docs.find(doc => doc.id == match.params.productId)
                return(<ProductsForm match={match} product={product} />)
              }}></Route>
          </div>
        </div>
      </ShopContext.Provider>
    )
  }
}


export default Shop
