import React from 'react';
import Select from 'react-select';
import ShopContext from '../Shop/ShopContext'

class ProductSubMenuSelect extends React.Component {

  handleChange = (selectedOption) => {
    this.props.setFieldValue('subMenuId', selectedOption.value)
    console.log(`Selected: ${selectedOption.value}`);
  }
  render() {

    const value = this.props.selectedOption

    return (
      <Select
        name="subMenuId"
        value={value}
        ref={(ref) => { this.select = ref; }}
        onChange={this.handleChange}
        options={this.props.options}
      />
    );
  }
}

const ProductSubMenuSelectWithContext = ({value, setFieldValue}) => (
  <ShopContext.Consumer>
    {shop =>{
      let options = shop.subMenus.map((subMenu) => {
        let id = subMenu.id
        let name = (subMenu.get('name') && subMenu.get('name').en) || id
        return({ value: id, label: name })
      })
      let selectedOption = options.find(o => o.value === value)
      return(<ProductSubMenuSelect
        selectedOption={selectedOption}
        options={options}
        setFieldValue={setFieldValue} />)
    }}
  </ShopContext.Consumer>
)

export default ProductSubMenuSelectWithContext
