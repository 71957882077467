import React, {Component} from 'react'
import Photo from '../Photo'


class OtherLogos extends Component {
  selectPhoto = (selectedPhoto) => {
    let shops = this.props.shops
    let oldPhoto = shops.get('logos')
    shops.ref.update({
      photo: selectedPhoto
    })
  }
  removePhoto = () => {
    let shops = this.props.shops
    // let oldPhoto = shops.get('logos')
    shops.ref.update({
      photo: null
    })
  }
  render() {
    let otherPhotos = this.props.shops && this.props.shops.get('otherLogos')
    console.log(otherPhotos)
    if (!otherPhotos) {return null}
    return(
      <div>
        {Object.keys(otherPhotos).map(id => {
          let photo = otherPhotos[id]
          return(
            <div className="choice"
              key={id}
              onClick={this.selectPhoto.bind(this, photo)}
              >
              <Photo {...photo} width={30} />
            </div>
          )
        })}
        <div
          onClick={this.removePhoto}
          >❌</div>
      </div>
    )
  }
}

export default OtherLogos
